
import { Component, Prop, Vue } from "vue-property-decorator";
import { AssistantListItem } from "./assistantsWebServices";
import AssistantGallery from "./AssistantGallery.vue";
import { getBaseImagesUrl } from "@/helpers/settingsHelper";

@Component({
  components: {
    AssistantGallery,
  },
})
export default class Assistant extends Vue {
  @Prop() assistant!: AssistantListItem | undefined;

  get imagesUrl() {
    return getBaseImagesUrl();
  }
}
