

import { Component, Prop, Vue } from "vue-property-decorator"
import { AssistantListItem } from "./assistantsWebServices"
import AvailabilityIndicator from "@/components/AvailabilityIndicator.vue"
import AssistantMap from "./AssistantMap.vue"
import { OnlineStatus, UserAvailabilityModel } from "@/serviceClients/userStatusWebServices"
import { getBaseImagesUrl } from "@/helpers/settingsHelper"
import store from "@/store";
import {LoggedUserInformation} from "@/login/loginModel";
import Assistant from "./Assistant.vue";

@Component({
  components:{
    AvailabilityIndicator,
    AssistantMap,
    Assistant
  }
})
export default class AssistantListResultItems extends Vue {
    @Prop() isLoading!: boolean
    @Prop() showMap!: boolean
    @Prop() searchResults!: AssistantListItem[]
    @Prop() availabilities!: UserAvailabilityModel[]
    @Prop() resetFilters!: () => void

    showAllResults = true
    maxResultsLength = 60

    userStatus(userId: string): OnlineStatus | undefined {
      return this.availabilities.find((a) => a.userId === userId)?.status;
    }

  get imagesUrl() {
    return getBaseImagesUrl();
  }

  get loggedUser() {
    return store.getters.module.user as LoggedUserInformation;
  }

  isShownAssistantItem(index: number): boolean {
      if (this.loggedUser){

          if (this.showAllResults) {
              return true
          }

          if (index > this.maxResultsLength - 1) {
              return false
          }
      }

      return index <= this.maxResultsLength - 1
  }

  get seeMoreInformationShown(): boolean {
    return !this.loggedUser && this.searchResults.length >= this.maxResultsLength
  }

  get seeMoreInformationForLoggedUserShown(): boolean {
    return this.loggedUser && this.searchResults.length >= this.maxResultsLength
  }

  onClickSeeMoreResults () {
    this.showAllResults = true
  }
}
