
import { getGoogleApiKey } from "@/helpers/settingsHelper";
import { Component, Prop, Vue } from "vue-property-decorator";
import AssistantMapMarker from "./AssistantMapMarker.vue";
import { AssistantListItem } from "./assistantsWebServices";

@Component({
    components: {
    }
})
export default class AssistantMap extends Vue {
    @Prop() list!: AssistantListItem[];

    async mounted() {
        await (Vue as any).loadScript(`https://maps.googleapis.com/maps/api/js?key=${getGoogleApiKey()}&libraries=&v=weekly`);

        this.$watch(() => this.list, () => {
            this.initMap();
        })
        this.initMap();
    }
	
    initMap(): void {
        const myLatLng = { lat: 52.1622725, lng: 21.0285816};
        const map = new google.maps.Map(
            this.$el,
            {
                zoom: 10,
                center: myLatLng
            }
        );
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position: any) => {
                    const pos = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };
                    map.setCenter(pos);
                }
            );
        }

        let infoWindow: google.maps.InfoWindow;

        this.list.forEach((item) => {
            if(!item.latitude || !item.longitude) return;
            const marker = new google.maps.Marker({
                position: {
                    lat: item.latitude,
                    lng: item.longitude
                },
                map: map,
                title: `${item.firstName} ${item.lastName}`,
                clickable: true
            });
            google.maps.event.addListener(marker, 'click', function () {
                // close window if not undefined
                if (infoWindow !== void 0) {
                    infoWindow.close();
                }
                
                const markerTemplate = new AssistantMapMarker();
                markerTemplate.assistant = item;

                const infoWindowOptions = {
                    content: markerTemplate.$mount().$el.outerHTML
                };
                infoWindow = new google.maps.InfoWindow(infoWindowOptions);
                infoWindow.open(map, marker);
            });

        })
    }
}
