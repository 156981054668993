
import { Component, Prop, Vue } from "vue-property-decorator";
import { getBaseImagesUrl } from "../helpers/settingsHelper";
import { AssistantListItem } from "./assistantsWebServices";

@Component({
    components: {
    }
})
export default class AssistantMapMarker extends Vue {
    @Prop() assistant!: AssistantListItem | undefined;

    get imagesUrl() {
        return getBaseImagesUrl();
    }

    get avatarId() {
        return this.assistant?.photoId;
    }
}
