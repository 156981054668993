
import { OnlineStatus } from "@/serviceClients/userStatusWebServices";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class AvailabilityIndicator extends Vue {
  @Prop() status!: OnlineStatus;

  get available() {
    return this.status === OnlineStatus.Available;
  }

  get busy() {
    return this.status === OnlineStatus.Busy;
  }

  get offline() {
    return this.status === OnlineStatus.Offline;
  }
}
