

import { Component, Prop, Vue } from 'vue-property-decorator';
import AssistantList from '@/assistants/List.vue';
import AssistantListMobile from "@/assistants/ListMobile.vue";
import { SearchAssistantModel } from './searchWebServices';
import store from '@/store';

@Component({
  components: {
    AssistantList,
    AssistantListMobile,
  }
})
export default class AssistantsRoot extends Vue {
  @Prop() filters!: SearchAssistantModel;

  mounted() {
    store.commit.module.setActiveModuleName(this.$t("Assistants"));
  }

}
