
import { Component, Mixins } from "vue-property-decorator";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { getCategoryTree } from "@/helpers/categoryTreeMapper";
import languages from '@/core/translate/translations/languageCodesTranslations/languages.json';
import { getLanguage } from '@/core/translate'
import { getLevelOptionsTranslations } from "../accountSetup/languageLevelHelper";
import { getMaritalStatusOptionsTranslations } from "../accountSetup/maritalStatusHelper";
import AssistantListResultItems from "./ListResultItems.vue"
import { Gender } from "@/accountSetup/accountSetupService";
import AssistantListBase from './ListBase'

@Component({
  components: {
    Treeselect,
    AssistantListResultItems,
  }
})
export default class AssistantList extends Mixins(AssistantListBase) {

  mounted() {
    super.mounted()
  }

  get genderOptions() {
    return [
      { label: this.$t('Male'), id: Gender.Male },
      { label: this.$t('Female'), id: Gender.Female },
    ]
  }

  get maritalStatusOptions() {
    return getMaritalStatusOptionsTranslations(this).map((x) => {
      return {
        id: x.value,
        label: x.text,
      }
    })
  }

  get categoryOptions() {
    return getCategoryTree(this, this.categories);
  }

  get countryOptions() {
    return this.countries.map((c) => {
      return {
        label: c.text,
        id: c.value,
      };
    });
  }

  get hobbyOptions() {
    return this.hobbies.map((c) => {
      return {
        label: c.name,
        id: c.id,
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label));
  }

  get interestOptions() {
    return this.interests.map((c) => {
      return {
        label: c.name,
        id: c.id,
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label));
  }

  get languageOptions() {
    return languages.map(l => {
      return {
        label: l[getLanguage()],
        id: l.code
      };
    })
  }

  get languageLevelOptions() {
    return getLevelOptionsTranslations(this).map(l => {
      return {
        label: l.text,
        id: l.value
      };
    });
  }

  get regionOptions() {
    return this.regions.map((region) => {
      return {
        id: region.id,
        label: region.name,
      }
    })
    .sort((a, b) => a.label.localeCompare(b.label))
  }

  cityOptions() {
    if (!this.showCityFilter) {
      return []
    }

    return this.regions.filter((region) => region.id === this.localFilters.regionId)[0].cities.map((city) => {
      return {
        id: city.id,
        label: city.name,
      }
    })
    .sort((a, b) => a.label.localeCompare(b.label))
  }
}
