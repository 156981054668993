
import { Component, Vue } from "vue-property-decorator";
import { SearchAssistantModel } from "../assistants/searchWebServices";
import AssistantsRoot from "@/assistants/AssistantsRoot.vue";

@Component({
  components: {
    AssistantsRoot,
  },
})
export default class Search extends Vue {
  get filters() : Partial<SearchAssistantModel> {
    return {
      phrase: this.$route.params.phrase,
    };
  }
}
