

import { Component, Vue } from 'vue-property-decorator';
import { SearchAssistantModel } from '../assistants/searchWebServices';
import AssistantsRoot from "@/assistants/AssistantsRoot.vue";

@Component({
  components: {
    AssistantsRoot,
  }
})
export default class Assistants extends Vue {
  get filters(): Partial<SearchAssistantModel> {
    return {
      categories: [ this.$route.params.id ],
    };
  }
}
