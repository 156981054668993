
import { Component, Vue } from "vue-property-decorator";
import AssistantsRoot from "@/assistants/AssistantsRoot.vue";

@Component({
  components: {
    AssistantsRoot,
  },
})
export default class Assistants extends Vue { }
