import { pushError } from "@/core"
import { CategoryGroup, fetchCategories } from "@/serviceClients/categoryWebServices"
import { fetchHobbies, fetchInterests, HobbyOrInterest } from "@/serviceClients/hobbyAndInterestWebServices"
import { getRegions, Region } from "@/serviceClients/regionWebService"
import { OnlineStatus, onlineStatusBatch, UserAvailabilityModel } from "@/serviceClients/userStatusWebServices"
import store from "@/store"
import { Component, Prop, Vue } from "vue-property-decorator"
import { AssistantListItem } from "./assistantsWebServices"
import countries from "@/dictionaries/countries"
import { SearchAssistantModel, searchForAssistants } from "./searchWebServices"
import { Dictionary } from "vue-router/types/router"
import { LanguageLevel } from "@/accountSetup/accountSetupService"

@Component
export default class AssistantListBase extends Vue {
  @Prop() filters: Partial<SearchAssistantModel> | undefined

  emptyFilters: SearchAssistantModel = {
    languages: [{}],
    hobbies: [],
    interests: [],
    categories: [],
    categoryGroups: [],
  }

  localFilters: SearchAssistantModel = JSON.parse(JSON.stringify(this.emptyFilters))

  assistants: AssistantListItem[] = []
  assistantsAvailabilites: UserAvailabilityModel[] = []
  isLoading = true
  hobbies: HobbyOrInterest[] = []
  interests: HobbyOrInterest[] = []
  categories: CategoryGroup[] = []

  countries: { text: string, value: string }[] = []
  regions: Region[] = []

  showMap = false

  mounted() {
    store.commit.module.setAssistantSearchUrlToRedirect(this.$route.fullPath)

    this.localFilters = Object.assign({}, this.localFilters, this.filters)
    if (!!this.filters) {
      this.emptyFilters = Object.assign({}, this.emptyFilters, this.filters)
    }

    const queryFilters = this.parseQueryParams()
    this.localFilters = Object.assign({}, this.localFilters, queryFilters)

    this.countries = countries;

    this.addPagedSearchingWithInfiniteScroll();

    (async () => {
      this.hobbies = (await fetchHobbies()).data
      this.interests = (await fetchInterests()).data
      this.categories = (await fetchCategories()).data
      this.instantFilterChanged()
    })().catch()
  }

  private lastSearchId: number = 0
  private pageSize = 60
  private currentPageNumber: number = 0
  private lastSearchResultCount: number = -1

  async search() {
    this.isLoading = true

    const searchId = Math.random()
    this.lastSearchId = searchId

    try {
      this.currentPageNumber++
      const searchResult = (await searchForAssistants({ ...this.localFilters, pageNumber: this.currentPageNumber, pageSize: this.pageSize })).data
      this.lastSearchResultCount = searchResult.length

      if (this.lastSearchId !== searchId) {
        return
      }

      this.assistants.push(...searchResult)

      if (!store.getters.module.user) {
        return
      }

      this.assistantsAvailabilites = (await onlineStatusBatch(this.assistants.map((r) => r.id))).data
    } catch (error) {
      pushError((error as any).response?.data?.error || error, {
        title: this.$t("An error occurred while fetching data"),
      })
    } finally {
      if (this.lastSearchId === searchId) {
        this.isLoading = false
      }
    }
  }

  addPagedSearchingWithInfiniteScroll() {
    if (!store.getters.module.user) {
      return
    }

    window.addEventListener('scroll', async (_) => {
      if (this.lastSearchResultCount === this.pageSize
        && !this.isLoading
        && window.document.body.clientHeight - window.scrollY < 3000) {
        await this.search()
      }
    })
  }

  async fetchRegions() {
    if (!this.localFilters.countryCode) {
      return
    }

    this.regions = (await getRegions(this.localFilters.countryCode)).data
  }

  private searchTimeout: any = null

  filterChanged(timeout: number) {
    clearTimeout(this.searchTimeout)

    this.searchTimeout = setTimeout(async () => {
      await this.$router.push({
        path: this.$route.path,
        query: this.parseFiltersToQuery(),
      })

      store.commit.module.setAssistantSearchUrlToRedirect(this.$route.fullPath)

      this.assistants.splice(0, this.assistants.length)
      this.currentPageNumber = 0
      
      await this.search()
    }, timeout)
  }

  instantFilterChanged() {
    this.filterChanged(0)
  }

  delayedFilterChanged() {
    this.filterChanged(1200)
  }

  resetFilters() {
    this.localFilters.languages.splice(0, this.localFilters.languages.length, {})
    this.localFilters.hobbies.splice(0)
    this.localFilters.interests.splice(0)

    this.localFilters = Object.assign({}, this.emptyFilters)

    this.regions.splice(0)

    this.filterChanged(50)
  }

  get assistantList() {
    return this.assistants
  }

  removeLanguage(index: number) {
    this.localFilters.languages.splice(index, 1)
    if (!this.localFilters.languages.length) {
      this.localFilters.languages.push({})
    }

    if (!(this as any).$isMobile()) {
      this.filterChanged(50)
    }
  }

  async countryChanged() {
    if (!(this as any).$isMobile()) {
      this.filterChanged(50)
    }

    if (!this.localFilters.countryCode) {
      this.regions.splice(0, this.regions.length)
      this.regionRemoved()
      return
    }

    await this.fetchRegions()
  }

  countryRemoved() {
    this.$set(this.localFilters, 'countryCode', undefined)
    this.regionRemoved()
  }

  regionChanged() {
    this.filterChanged(50)
  }

  regionRemoved() {
    this.$set(this.localFilters, 'regionId', undefined)
    this.cityRemoved()
  }

  cityChanged() {
    this.filterChanged(50)
  }

  cityRemoved() {
    this.$set(this.localFilters, 'cityId', undefined)
  }

  addLanguage() {
    this.$set(this.localFilters.languages, this.localFilters.languages.length, {})
  }

  userStatus(userId: string): OnlineStatus | undefined {
    return this.assistantsAvailabilites.find((a) => a.userId === userId)
      ?.status
  }

  get showRegionFilter(): boolean {
    return !!this.localFilters.countryCode && !!this.regions.length
  }

  get showCityFilter(): boolean {
    if (!this.showRegionFilter || !this.localFilters.regionId) {
      return false
    }

    const regions = this.regions.filter((region) => region.id === this.localFilters.regionId)

    if (!regions.length) {
      return false
    }

    return true
  }

  parseQueryParams(): SearchAssistantModel {
    const queryFilters: SearchAssistantModel = JSON.parse(JSON.stringify(this.emptyFilters))

    Object.entries(this.$route.query).forEach(
      ([key, value]) => {
        if (key === 'hobbies' ||
          key === 'interests'
        ) {
          if (Array.isArray(value))
            queryFilters[key].push(...(value as string[]))
          else
            queryFilters[key].push(value)
        }
        else if (key === 'lang' || key === 'langLvl') {
          if (!queryFilters['languages']) {
            queryFilters['languages'] = []
          }
          if (Array.isArray(value)) {
            (value as string[]).forEach((x, i) => {
              if (x === 'none') {
                return
              }

              if (!queryFilters['languages'][i]) {
                queryFilters['languages'][i] = {}
              }

              if (key === 'lang' && x !== 'none')
                queryFilters['languages'][i].language = x
              else if (key === 'langLvl' && x !== 'None')
                queryFilters['languages'][i].level = LanguageLevel[x as keyof typeof LanguageLevel]
            })
          } else {
            if (key === 'lang' && value !== 'none')
              queryFilters['languages'][0].language = value
            else if (key === 'langLvl' && value !== 'None')
              queryFilters['languages'][0].level = LanguageLevel[value as keyof typeof LanguageLevel]
          }
        }
        else {
          // @ts-ignore: dynamic way of parsing query, which typescript does not accept
          queryFilters[key] = value as any
        }
      }
    )

    return queryFilters as SearchAssistantModel
  }

  parseFiltersToQuery(): Dictionary<string | string[]> {
    const query: Dictionary<string | string[]> = {}

    Object.entries(this.localFilters).forEach(([key, value]) => {
      if (key === 'categories') {
        return
      }

      if (key === 'languages') {
        query['lang'] = this.localFilters[key].filter((x) => x.language || x.level).map((x) => x.language ?? 'none')
        query['langLvl'] = this.localFilters[key].filter((x) => x.language || x.level).map((x) => x.level !== undefined ? LanguageLevel[x.level].toString() : 'None')

        return
      }

      if (!!value || key === 'gender' || key === 'maritalStatus') {
        query[key] = value
      }
    })

    return query
  }
}
