
import {Component, Mixins} from "vue-property-decorator";
import {getCategoryTree} from "@/helpers/categoryTreeMapper";
import AssistantListResultItems from "./ListResultItems.vue"
import languages from "@/core/translate/translations/languageCodesTranslations/languages.json";
import {getLanguage} from "@/core/translate";
import {getLevelOptionsTranslations} from "../accountSetup/languageLevelHelper";
import {Gender, LanguageLevel} from "@/accountSetup/accountSetupService";
import {getMaritalStatusOptionsTranslations} from "@/accountSetup/maritalStatusHelper";
import AssistantListBase from './ListBase'

@Component({
  components: {
    AssistantListResultItems,
  },
})
export default class AssistantListMobile extends Mixins(AssistantListBase) {

  selectedCategoryGroup: any | null = null;

  onClickSearch () {
    this.instantFilterChanged()

    // @ts-ignore: method exists
    this.$refs.filtersMobile.hide()
  }

  mounted() {
    super.mounted()
  }

  get selectedCategoryGroupName() {
    return this.selectedCategoryGroup?.label ?? '';
  }

  getCategoryGroupButtonText(categoryGroup: any) {
    const allCategoryIdsInGroup: string[] = categoryGroup.options.flatMap((c: any) => c.value)
    const selectedCategoriesInGroupCount = this.localFilters.categories.filter((id) => allCategoryIdsInGroup.includes(id)).length
    return !selectedCategoriesInGroupCount
      ? categoryGroup.label.toUpperCase()
      : `${categoryGroup.label.toUpperCase()} (${selectedCategoriesInGroupCount})`
  }

  get maritalStatusOptions() {
    return getMaritalStatusOptionsTranslations(this)
  }

  get categoryOptions() {
    return getCategoryTree(this, this.categories).map((cat) => {
      return {
        label: cat.label,
        options: cat.children.map((x) => {
          return {
            id: x.id,
            label: x.label,
            options: x.children.map((opt) => {
              return {
                text: opt.label,
                value: opt.id,
              }
            })
          }
        })
      }
    })
  }

  get genderOptions() {
    return [
      {text: this.$t('Male'), value: Gender.Male},
      {text: this.$t('Female'), value: Gender.Female},
    ]
  }

  get countryOptions() {
    return [{ text: '', value: undefined }, ...this.countries ];
  }

  get hobbyOptions() {
    return this.hobbies.map((c) => {
      return {
        text: c.name,
        value: c.id,
      };
    })
    .sort((a, b) => a.text.localeCompare(b.text))
  }

  get interestOptions() {
    return this.interests.map((c) => {
      return {
        text: c.name,
        value: c.id,
      };
    })
    .sort((a, b) => a.text.localeCompare(b.text))
  }

  get languageOptions() {
    return [
      { text: '', value: undefined },
      ...languages.map((l) => {
        return {
          text: l[getLanguage()],
          value: l.code,
        };
      })
    ]
  }

  get languageLevelOptions() {
    return [
      { text: '', value: undefined },
      ...getLevelOptionsTranslations(this),
    ]
  }

  get regionOptions() {
    return [
      { text: '', value: undefined },
      ...this.regions.map((region) => {
        return {
          value: region.id,
          text: region.name,
        }
      }),
    ]
    .sort((a, b) => a.text.localeCompare(b.text))
  }

  cityOptions() {
    if (!this.showCityFilter) {
      return [];
    }

    return [
      { text: '', value: undefined },
      ...this.regions
        .filter((region) => region.id === this.localFilters.regionId)[0]
        .cities.map((city) => {
          return {
            value: city.id,
            text: city.name,
          };
        }),
    ]
    .sort((a, b) => a.text.localeCompare(b.text))
  }

  clearRegion() {
    this.localFilters.regionId = undefined
    this.$forceUpdate();
  }

  clearCity() {
    this.localFilters.cityId = undefined
    this.$forceUpdate();
  }

  selectLanguage(index: number, language: string) {
    this.localFilters.languages[index].language = language;
  }

  selectLanguageLevel(index: number, level: LanguageLevel) {
    this.localFilters.languages[index].level = level;
  }
}
